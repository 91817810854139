import { PlatformID } from '@common/clients/api';

import { NewsListInsert, PlatformIncludeInserts } from './types';

//prettier-ignore
export const PLATFORM_INCLUDES: PlatformIncludeInserts = {
    [PlatformID.BI]: [],
    [PlatformID.BR]: [
        NewsListInsert.LATEST_VIDEO_ARTICLES,
        NewsListInsert.VIDEO_PLAYER,
    ],
    [PlatformID.GP]: [
        NewsListInsert.HEADLINES_DOSSIER,
        NewsListInsert.UPCOMING_GP,
        NewsListInsert.ODDS_DOSSIER,
        NewsListInsert.VIDEO_PLAYER,
    ],
    [PlatformID.MH]: [],
    [PlatformID.RW]: [],
    [PlatformID.VI]: [
        NewsListInsert.ODDS_DOSSIER
    ],
    [PlatformID.VN]: [
        NewsListInsert.ODDS_DOSSIER,
        NewsListInsert.VIDEO_PLAYER,
    ],
    [PlatformID.VP]: [
        NewsListInsert.ODDS_DOSSIER,
    ],
    [PlatformID.WH]: [],
};

export const shouldInclude = (platform: PlatformID, insertType: NewsListInsert) =>
    PLATFORM_INCLUDES[platform]?.includes(insertType);
