'use client';

import { useCallback, useState } from 'react';

import { useTranslation } from '@cms/i18n';
import { ReactPortal } from '@common/atoms/ReactPortal';
import {
    Author,
    CuratedNewsItem,
    GrandPrix,
    HeadlinesDossier,
    ModuleScheduleSection,
    News,
    PlatformID,
} from '@common/clients/api';
import { ContextHash } from '@common/defaults';
import { DefaultServerSideProps } from '@common/types/DefaultProps';
import { ItemsPerPage } from '@common/types/ItemsPerPage';
import { useContextData } from '@common/useContextData';
import { fetchHomepageNews } from '@web/handlers/fetchHomepageNews';
import { Ad, AdPlacement } from '@web/molecules/Ad';
import { ArticlePromoItem } from '@web/molecules/ArticlePromoItem/ArticlePromoItem';
import { AvatarList } from '@web/molecules/AvatarList';
import { FeaturedCarrousel } from '@web/molecules/FeaturedCarrousel';
import { MatchTicker } from '@web/molecules/MatchTicker';
import { NewsList } from '@web/molecules/NewsList';
import { DisplayType } from '@web/molecules/NewsList/config/types';
import { NotificationBarHolder } from '@web/molecules/NotificationBar';
import { Props as OddsDossierIntroProps } from '@web/molecules/OddsDossierIntro/OddsDossierIntro';
import { MetaHead } from '@web/organisms/MetaHead';
import { Sidebar } from '@web/organisms/Sidebar';
import { VideoCarousel } from '@web/organisms/VideoCarousel';
import styles from '@web/templates/HomePage/HomePage.module.scss';
import { HomeNewsList } from '@web/templates/HomePage/organisms/HomeNewsList/HomeNewsList';
// TODO: set this value correctly after having the update news article implemented
// [PB-3973]
const isEditable = false;

export interface State {
    currentPage: number;
    hasNextPage: boolean;
    news: News[];
}

export interface Props {
    contextHash: ContextHash;
    state: State;
    featuredItems: CuratedNewsItem[];
    headlinesDossier?: HeadlinesDossier;
    oddsDossier?: OddsDossierIntroProps;
    upcomingGP?: GrandPrix;
    videonews: News[];
    authors?: Author[];
}

export const HomePage = ({
    contextData,
    state: initialState,
    featuredItems,
    headlinesDossier,
    oddsDossier,
    upcomingGP,
    videonews,
    authors,
}: Props & DefaultServerSideProps) => {
    const platform = contextData?.platform;
    const { modulesBySection } = useContextData();
    const [state, setState] = useState<State>(initialState);
    const { currentPage, news, hasNextPage } = state;
    const promotedArticle = modulesBySection?.[ModuleScheduleSection.PROMOTED_ARTICLE]?.[0];
    const backgroundColor = promotedArticle?.bookmaker?.backgroundColor;

    const __meta = useTranslation('meta').t;
    const metaLatestVideosTitle = __meta(`${platform.id}-homepage.latest-videos-title`);
    const onReadMoreClick = useCallback(async () => {
        const newState = {
            ...state,
            currentPage: currentPage + 1,
        };

        const news = await fetchHomepageNews(contextData, newState.currentPage, true, ItemsPerPage.HUGE);

        newState.news = [...state.news, ...(news?.data || [])];
        newState.hasNextPage = news?.pagination?.hasNextPage || false;

        setState(newState);
    }, [state, currentPage, contextData, setState]);

    const showCarousel = [PlatformID.BR, PlatformID.GP].indexOf(platform.id) === -1;

    return (
        <div className={styles.HomePage}>
            <MetaHead
                title={__meta(`${platform.id}-homepage.title`)}
                description={__meta(`${platform.id}-homepage.description`)}
            />
            <Ad placement={AdPlacement.HOME_BEFORE_CONTENT} />
            <FeaturedCarrousel featuredItems={featuredItems} isEditable={isEditable} legacyLayout={true} />
            <Ad placement={AdPlacement.HOME_AFTER_CAROUSEL} />
            {/* TODO handle this sidebar leftover */}
            {/* {sidebarProps && sidebarProps?.[WidgetType.UPCOMING_GP] ? (
                <ResponsiveToggle
                    mobile={
                        <GrandPrixWidget
                            {...sidebarProps[WidgetType.UPCOMING_GP]}
                            superTitle={__meta`f1-calendar.upcoming-race`}
                        />
                    }
                />
            ) : null} */}
            {authors && authors.length ? <AvatarList authors={authors} /> : null}
            <ReactPortal containerID={'_next_video_carousel_portal'}>
                <VideoCarousel />
            </ReactPortal>
            <div className="main-content">
                {showCarousel ? (
                    <NewsList
                        title={<h3>{metaLatestVideosTitle}</h3>}
                        items={videonews}
                        moreArticlesLink={__meta('url:videos')}
                        displayType={DisplayType.carrousel}
                        displayTypeSwitchOptions={[]}
                        isHighlighted={false}
                        showIconTabs={false}
                    />
                ) : null}
                <Ad placement={AdPlacement.HOME_BEFORE_NEWS_LIST} />
                {promotedArticle ? (
                    <>
                        <h3
                            style={
                                backgroundColor
                                    ? ({ '---backgroundColor': backgroundColor } as React.CSSProperties)
                                    : {}
                            }
                            className={styles['promoted']}
                        >{__meta`promoted`}</h3>
                        <ArticlePromoItem platform={platform?.id} moduleScheduleItem={promotedArticle} />
                    </>
                ) : null}
                <HomeNewsList
                    news={news}
                    platform={platform.id}
                    hasNextPage={hasNextPage}
                    onReadMoreClick={onReadMoreClick}
                    headlinesDossier={headlinesDossier}
                    oddsDossier={oddsDossier}
                    upcomingGP={upcomingGP}
                    videonews={videonews}
                />
            </div>
            <MatchTicker />
            <Sidebar />
            <NotificationBarHolder />
        </div>
    );
};
